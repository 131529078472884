*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background-color: azure;
}

/* NAVBAR */
.nav-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 12px 15px 10px;
  position: fixed;                         
  flex-wrap: wrap;
  z-index: 2;
}
.nav-changecolor{
  background-color: #184777;
  color:white;
}
.nav-changecolor .nav-bar-name a{
  color: white;
}
.nav-changecolor li a{
  color: white;
}
.nav-bar-name{
  font-size: 28px;
  font-family: 'Josefin Sans', sans-serif;
  padding-left: 25px;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.2);
  
}
.nav-bar-name span{
  color:#1976d2;
}
.nav-bar-btn{
  margin-right: 24px;
}
.nav-bar-btn ul{
  display: flex;
  flex-wrap: wrap;
}
.nav-bar-btn ul li{
  text-decoration: none;
  list-style: none;
  padding: 10px 0 10px 10px;
}
.nav-bar-btn a{
  text-decoration: none;
  color: black;
  font-size: 18px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding: 20px 20px;  
  margin-top: 20px;
  text-transform: uppercase;
}
.nav-bar-btn a:hover{
  background-color: #4c88c4;
  transition: 0.9s;
  border-radius: 8px;
}
.nav-bar-tab{
  margin: 5px;
}
.nav-bar-tab:hover{
  background-color: #d2e0ee;
}
@media only screen and (min-width:940px){
  .navBar{
    display: none;
  }
}
@media only screen and (max-width:940px){
  /* sidebar Nav */
.nav-bar-btn{
  display: none;
}
.navBar {
  position: relative;
}
 li a{
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  color: white;
}
.navBar button {
  position: fixed;
  right: 30px;
  font-size: 30px;
  top: 20px;
  z-index: 10;
  cursor: pointer;
  border: #184777;
}
.navbarhamburger{
  background-color: azure;
}

.menuNav {
  margin-top: 60px;
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background-color: rgba(24,71,119,0.8 ) ;
    left: 0;
  bottom: 0;
  height: 50vh;
  width: 0;
  overflow: hidden;
  max-width: 600px;
  z-index: 9;
}

.menuNav.showMenu {
  width: 100%;
}

a {
  display: block;
  padding: 10px 40px;
  text-decoration: none;
  color: #3fffd2;
  text-transform: uppercase;
  font-weight: bold;
}

.menuNav li:first-child {
  margin-top: 7rem;
}
}

/* HOME */

.home{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
  padding: 50px 20px;
}
.home-cover{
  padding-top: 30px;
  margin-top: 15vh;                
}
.home-cover img{
  width: 65vh;
}
.home-content{
  margin-top: 15vh;
  width: 50%;
}
.content{
  padding: 10px 0;
}
.home-content1{
  background-color: #1976d2;
  display: inline-flex;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 10px;
  color: white;
  border-radius: 24px 10px 10px 0;
  box-shadow: 3px 4px rgba(0, 0, 0, 0.2);
}

.home-content2{
  font-family: 'Josefin Sans', sans-serif;
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  padding-top: 20px;
}
.home-content3{
  font-family: 'Josefin Sans', sans-serif;
  font-size: 24px;
  font-weight: 550;
  color: #1976d2;
  text-shadow: 2px 1px rgba(159, 163, 197, 0.2);;
}

.home-content4{
  width: 100%;
}
.home-content4 p{
  width: 100%;
  font-size: 18px;
  line-height: 1.4;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.home-content5{
  display: flex;
  width: 100%;
  justify-content: left ;
  align-items: center;
  margin-top: 10px; 
  flex-wrap: wrap;
}
.home-content5 a{
  background-color: azure;
  padding: 6px 8px;
  font-size: 20px;
  border: 1px solid rgb(35, 95, 95) ;
  border-radius: 50%;
  color: #1976d2;
  box-shadow: 2px 3px rgba(0,0,0,0.2);
  margin-right: 30px;
  margin-top: 10px;
}
.home-content5 a:hover{
  background-color: rgb(202, 224, 224);
  padding: 8px 10px;
}

/* ABOUT */

.about{
  display: flex;
  align-items: center ;
  justify-content: center;
  width: 100%;
  margin-top:50px;
  margin-bottom: 30px;
  padding-top: 80px;
  flex-wrap: wrap;
}
.about-me{
  width: 50%;
  display: flex;
  flex-direction: column;
}
.me{
  margin-top: 20px;
}
.about-me-pic{
  margin-top: 80px;
}

.about-me-pic img{
  width: 60vh;
}
.about-me-heading{
  color: #1976d2;
  font-size: 30px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  text-shadow: 2px 3px rgba(0, 0, 0, 0.2);;
}
.about-me-contents{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 18px;
  line-height: 1.4;
}
.about-me-logo{
  font-size: 20px;
  color: #1976d2;
}
.about-me-list{
  padding: 5px 3px;
}
.about-me-details li{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none ;
  padding-top: 10px;
  font-size: 18px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  width: 100%;
}

.download-cv-btn button{
  padding: 15px;
  border-radius: 10px;
  background-color: #1976d2;
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Josefin Sans', sans-serif;
  border: none;
  box-shadow: 2px 3px rgba(0,0,0,0.3);
}
.download-cv-btn  a{
  text-decoration: none;
  color: white;
}
.download-cv-btn button:hover{
  background-color: #184777;
}


/* SKILLS */

.skills{
  display: flex;
  flex-direction: column;
  margin: 100px 0;
  align-items: center;
  padding: 50px 0;
}

.skill-heading{
  text-align: center;
  margin: 30px 0;
  padding: 20px;
  color: #1976d2;
  font-size: 45px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  text-shadow:2px 3px rgba(0,0,0,0.2);
}
.skill-list{
  background-color: #4c88c4;
  display: flex;
  flex-direction: row;
  min-height: 50vh;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
  align-items: center;
  box-shadow: 4px 6px rgba(0, 0, 0, 0.2);
}

.skill-set{
  display: flex;
  flex-direction: column;
  padding: 70px;
  width: 30%;
  align-items: center;
  font-size: 90px;
  color: azure;
  cursor: pointer;
}

.skill-set span{
  font-size: 20px;
  padding-top: 10px;
  font-family: 'Josefin Sans', sans-serif;
  text-shadow: 2px 3px rgba(0, 0, 0, 0.2);
}
.skill-set:hover{
  font-size: 110px;
  padding: 50px;
}


/* PORTFOLIO */

.projects{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.project-heading{
  width: 100%;
  text-align: center;
  margin-top: 100px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 45px;
  padding: 0 20px 20px 20px;
  color: #1976d2;
  font-weight: 600;
  text-shadow:2px 3px rgba(0,0,0,0.2);
}

.project-nav{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-top: 0px;
  padding: 20px;
  
}
.pnav{
  padding: 10px 20px;
}
.pnav button{
  text-decoration: none;
  font-family: 'Josefin Sans', sans-serif;
  color: black;
  font-size: 24px;
  background-color:#e4f3ff;
  padding:10px 15px;
  border-radius: 16px;
  box-shadow: 2px 3px rgba(0, 0, 0, 0.2);
  border: none;
}
.pnav-name button{
  background-color: #1976d2;
  color: white;
}

.pnav button:hover{
  background-color: azure;
  color: #1976d2;
}
.project-main{
  display: flex;
  justify-content: center;  
  align-items: center;
  margin: 25px 0;
  padding: 40px 0;
  width: 80%;
  flex-wrap: wrap;  
  background-color:#4c88c4 ;
}


.project-card{
  display:flex;
  flex-wrap: wrap;
  padding: 20px;
}
.pml{
  padding: 20px 40px;
}
.cards:hover{
  padding: 0;
  box-shadow: 12px 16px rgba(0, 0, 0, 0.2);
  opacity: 0.7;
}
.cards-react{
  width: 260px;
}

/* CONTACTS */

.contact{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-heading{
  width: 100%;
  text-align: center;
  margin-top: 100px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 45px;
  padding: 20px;
  color: #1976d2;
  font-weight: 600;
  text-shadow:2px 3px rgba(0,0,0,0.2);
}
.contact-input-main{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 60%;
  flex-wrap: wrap;
}
 .contact-input{
  display: flex;
  align-items: center;
  width: 33.33%;  
  justify-content: center;
}
.contact-name{
  padding-right: 10px;
}
.contact-email{
  padding: 0 10px;
}
.contact-subject{
  padding-left: 10px;
}
.contact-input input{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 30px 10px 5px;
  font-size: 16px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.contact-message{
  display: flex;
  justify-content: center;
  width:100%;
  margin-top: 20px;
  padding: 10px 0px;
}
.contact-message textarea{
  width: 100%;
  padding: 10px 0 70px 5px;
  font-size: 16px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.contact-send-btn{
  width: 100%;
  text-align: center;
  margin: 30px 0 ;
  font-family: 'Josefin Sans', sans-serif;
} 
.contact-send-btn input{
  padding: 15px;
  background-color: #1976d2;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  font-size:16px;
  font-weight: 600;
  border: none;
  border-radius: 12px;
  box-shadow: 2px 3px rgba(0, 0, 0, 0.3);
}
.contact-send-btn input:hover{
  background-color: #184777;
}
.footer{
  background-color:#4c88c4;
  color: white;
  text-align: center;
  margin-top: 50px;
  padding: 30px 0 20px 0;
}
.footer-content{
  padding: 10px 0;
  font-size: 22px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

@media only screen and (max-width:770px){
  img{
    width: 100% !important;
  }
  .about-me-pic img{
    display: none;
  }
  .about-me{
    margin-top: 25px;
    width: 85%;
  }
  .about-me-details{
    margin-right: -30px;
  }
  .home{
    padding-bottom: 10px;
  }
  .home-cover{
    margin: 20px;
  }
  .home-content{
    margin-top: 120px;
    width: 90%;
  }
  .skill-list{
    width: 90%;
  }
  .project-main{
    width: 90%;
  }
  .contact-input-main{
    width: 100%;
  }
  .contact-input{
    width: 30%;
  }
  .contact-message{
    width:90%;
  }
  .footer-content{
    font-size: 18px;
  }
}

@media only screen and (max-width:300px){
  .cards-react{
    width: 100%;
  }
}
@media only screen and (max-width:420px){
  .home-content5 a{
    margin-right: 10px;
    font-size: 20px;
    
  }
  .home-content5{
    width: 100%;
  }
}